import React from "react";

import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import Privacy from '../components/Privacy/Privacy';
import Footer from '../components/Footer/Footer';

import BgDesktopSrc from '../images/hero-fire-bg-desktop.jpg';
import BgMobileSrc from '../images/hero-fire-bg-mobile.jpg';

const TermsPage = () => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}
    >
      <Header />
      <Hero
        img={{ mobile: BgMobileSrc, desktop: BgDesktopSrc }}
        title="privacy&nbsp;policy"
      />
      <Privacy />
      <Footer />
    </div>
  );
};

export default TermsPage;
