import React from 'react';
import { Link } from 'gatsby';
import useScript from '../../hooks/useScript';
import { Root, Container, StampImg, Title, Body } from './Privacy.style';

import StampSrc from '../../images/stamp-beige.svg';

const Terms = () => {
  useScript('https://app.termly.io/embed-policy.min.js');

  return (
    <Root>
      <Container>
        <StampImg src={StampSrc} alt="" />

        <Body>
          <div name="termly-embed" data-id="634c8453-8b5d-4c5b-b6b7-f401780fbf42" data-type="iframe"></div>
          {/* <Title>Privacy Policy</Title>
          <br/>
          <h3>COLLECTING AND USING NON-PERSONALLY IDENTIFIABLE INFORMATION</h3>
          <p>
            When visiting the site, certain non-personally-identifiable and aggregate 
            information is collected. This data helps us to analyze and improve the 
            usefulness of the information we provide on the website. This data may be 
            utilized by third-party companies for use in providing advertisements about 
            goods and services that may be of interest to you based on your web surfing 
            activities including your activities on this website. Third-party companies 
            may use non-cookie technologies to recognize your computer or device. Please 
            note that neither your web browser nor its settings to block cookies may 
            have any effect in allowing you to block their use. In order for you to 
            make an informed decision about whether to use and navigate our website, 
            we are providing you with the following information about how we collect 
            and use non-personally identifiable and aggregate information. By 
            "non-personally-identifiable information", we mean information that does 
            not identify you personally, but provides us with anonymous "usage data," 
            such as the number of unique visitors we receive, what pages are visited 
            most often and the navigation preferences and characteristics of our visitors.
          </p>
          <p>
            Web browser information. Web browsers collect and store information about 
            the type of device and operating system you are using to access our website, 
            as well as your device’s address for facilitating network communications. 
            Accessing this information helps us to establish a secure and consistent 
            connection to you during your visits to our website.
          </p>
          <p>
            "Cookie" technology. A "cookie" is an element of data that a website can send 
            to your browser when you link to that website. It is not a computer program 
            and has no ability to read data residing on your computer or instruct it 
            to perform any step or function. By assigning a unique data element to 
            each visitor, the website is able to recognize repeat users, track usage 
            patterns and better serve you when you return to that site. The cookie 
            does not extract other personal information about you, such as your name or address.
          </p>
          <p>
            Client-side page tagging. This technology uses code on each web page to write 
            certain information about the page and the visitor to a log when a page is 
            rendered to you by your web browser. "Tagging" does result in a JavaScript 
            program running in your browser, but it is limited to providing information 
            about the page that you are requesting and the configuration of your browser. 
            It will not read any of your data files, nor execute any additional programs. 
            It does not extract any personal information about you, such as your name or 
            email address. You can prevent tagging by disabling JavaScript in your browser, 
            but that may prevent you from using all of our website's functions.
          </p>
          <p>
            Tracking pixels or beacons. These techniques use electronic files to track 
            your navigation of our website, your completion of transactions and other 
            browsing behavior.IP Address: When you subscribe to an Internet Service Provider 
            (ISP), your computing device is assigned an IP Address. We track and store 
            this address to help us manage security and monitor usage volume and patterns.
          </p>
          <p>
            To learn more about internet based advertising or to opt-out of this type of 
            advertising on our web site including the use by third parties please 
            go to <a href="http://www.networkadvertising.org/choices/" target="_blank" style={{textDecoration: "underline"}}>http://www.networkadvertising.org/choices/</a>
          </p>
          <p>
           Please <Link to="/terms" style={{textDecoration: "underline"}}>click here</Link> to read the James Ownby Reserve <Link to="/terms"  style={{textDecoration: "underline"}}>Terms & Conditions</Link>.
          </p> */}
        </Body>
      </Container>
    </Root>
  );
};

export default Terms;
